/**
 * Adapted from Ravinder Reddy Kothabab
 * https://stackoverflow.com/a/77472484
 * CC BY-SA 4.0
 */

const EXIF_MARKER = 0xffe1;

/**
 * Removes EXIF data from a JPEG image.
 * @see https://www.media.mit.edu/pia/Research/deepview/exif.html
 */
const cleanExifData = (arrayBuffer: ArrayBuffer) => {
  let data = arrayBuffer;
  let dataView = new DataView(arrayBuffer);
  let offset = 2; // Skip the first two bytes (0xFFD8; JPEG magic number)

  while (offset < dataView.byteLength) {
    // Found an EXIF marker
    if (dataView.getUint16(offset) === EXIF_MARKER) {
      // EXIF marker data size is big-endian
      const segmentLength = 2 + dataView.getUint16(offset + 2, false);

      // Update the arrayBuffer and dataView
      data = removeSegment(data, offset, segmentLength);
      dataView = new DataView(data);
    } else {
      // Move to the next marker
      offset += 2 + dataView.getUint16(offset + 2, false);
    }
  }

  return data;
};

/** Creates a new buffer without the specified segment. */
const removeSegment = (
  buffer: ArrayBuffer,
  offset: number,
  length: number
): ArrayBuffer => {
  const modifiedBuffer = new Uint8Array(buffer.byteLength - length);
  modifiedBuffer.set(new Uint8Array(buffer.slice(0, offset)), 0);
  modifiedBuffer.set(new Uint8Array(buffer.slice(offset + length)), offset);

  return modifiedBuffer.buffer as ArrayBuffer;
};

/**
 * Sanitizes any potentially private user data from a file.
 * Currently only applies to JPEGs.
 */
export const sanitizeFile = (file: File): Promise<File> => {
  return new Promise((resolve) => {
    // Return early if file is not a JPEG
    // Only JPEG images have EXIF data
    if (file.type !== 'image/jpeg') {
      resolve(file);
    }

    const fr = new FileReader();
    fr.onload = function (this: FileReader) {
      // Exit early if the file reader didn't return an ArrayBuffer
      if (typeof this.result !== 'object' || this.result === null) {
        return resolve(file);
      }

      const cleanedBuffer = cleanExifData(this.result);
      const blob = new Blob([cleanedBuffer], { type: file.type });
      const newFile = new File([blob], file.name, { type: file.type });
      resolve(newFile);
    };
    fr.readAsArrayBuffer(file);
  });
};
